<template>
  <footer id="footer">
    <div class="footer-content">
      <div class="footer-section services">
        <div class="linksxo">
          <p @click="toggleContent($event)">直播频道<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://lsnext.vds.pub/room/VDS1" target="_blank">VDS1（LS版）</a>
            <a href="https://live.bilibili.com/27859584" target="_blank">VDS1（Bilibili版）</a>
          </div>
          <p @click="toggleContent($event)">平台<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://lsnext.vds.pub">VDS LiveStream</a>
            <a href="https://ls.vds.pub">VDS LiveStream（较旧版本）</a>
            <!-- <a href="https://plus.vdsvod.com" target="_blank">VDS Plus（升级中）</a> -->
          </div>
          <p @click="toggleContent($event)">特别活动<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <router-link to="/vds-events">VDS Events 特别活动</router-link>
            <router-link to="/vds-events/replay">在线回放</router-link>
          </div>
        </div>
      </div>
      <div class="footer-section programmes">
        <div class="linksxo">
          <p @click="toggleContent($event)">生活节目<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1207729" target="_blank">Q&A Time</a>
            <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1570721" target="_blank">VLOG Time</a>
          </div>
          <p @click="toggleContent($event)">产品印象&测评节目<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1256486" target="_blank">Open a box 开个箱吧</a>
            <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1247641" target="_blank">科技指南针</a>
            <a href="https://space.bilibili.com/1645489523/channel/collectiondetail?sid=1280406" target="_blank">Lazy美食（停止制作）</a>
          </div>
        </div>
      </div>
      <div class="footer-section contact">
        <div class="linksxo">
          <p @click="toggleContent($event)">官方视频账号<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://space.bilibili.com/482125479" target="_blank">Bilibili</a>
          </div>
          <p @click="toggleContent($event)">自媒体视频账号<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://space.bilibili.com/1645489523" target="_blank">Bilibili</a>
            <a href="https://www.youtube.com/@VDSpub" target="_blank">YouTube</a>
          </div>
          <p @click="toggleContent($event)">直播回放视频账号<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="https://space.bilibili.com/3537111152068630" target="_blank">Bilibili</a>
          </div>
          <p @click="toggleContent($event)">电子邮件<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <a href="mailto:baiv@vds.pub" target="_blank">到：创始人</a>
            <a href="mailto:bd@vds.pub" target="_blank">到：商务合作</a>
            <a href="mailto:hr@vds.pub" target="_blank">到：人事</a>
          </div>
        </div>
      </div>
      <div class="footer-section supportandtools">
        <div class="linksxo">
          <p @click="toggleContent($event)">帮助和支持<span class="arrow">&#9660;</span></p>
          <div class="linksxo-content">
            <router-link to="/docs">文档中心（升级中）</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      &copy; VDS 是由BaiVideo持有版权的标志 ©️南京唯迪爱思文化传媒有限公司 2024 保留所有权利。
    </div>
  </footer>
</template>

<script>
export default {
  name: "vc-footer",
  methods: {
    toggleContent(event) {
      // 只有在屏幕宽度小于等于768px时才会执行折叠操作
      if (window.innerWidth <= 768) {
        const header = event.currentTarget;
        const content = header.nextElementSibling;
        const maxHeight = content.style.maxHeight;
        if (maxHeight && maxHeight !== '0px') {
          content.style.maxHeight = '0';
        } else {
          content.style.maxHeight = content.scrollHeight + 'px';
        }
      }
    }
  },
  mounted() {
    // 处理 iOS Safari 的宽度问题
    const footer = document.querySelector('footer');
    if (footer && window.innerWidth <= 768) {
      footer.style.width = '100%';
    } else if (footer) {
      footer.style.width = '100vw';
    }
  }
};
</script>

<style scoped>
/* 页脚样式 */
footer {
  background-color: #0c0c0c;
  color: white;
  text-align: left;
  font-family: 'MiSans', Arial, sans-serif;
  width: 100vw;
  margin-top: 50px;
  box-sizing: border-box;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
}

.footer-bottom {
  padding: 40px;
  margin-top: 20px;
  color: gray;
  text-align: left;
}

.linksxo {
  display: flex;
  flex-direction: column;
}

.linksxo p {
  font-family: 'MiSans-Light', Arial, sans-serif;
  color: grey;
  font-size: 12px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  margin: 0 0 8px 0;
  font-weight: bold;
  position: relative;
}

.arrow {
  display: none;
  margin-left: 10px;
  font-size: 12px;
}

.linksxo-content {
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

/* 手机端样式 */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .linksxo-content {
    max-height: 0;
  }

  .arrow {
    display: inline-block;
  }

  footer {
    width: 100%;
  }
}

/* 其他端使用100vw */
@media (min-width: 769px) {
  footer {
    width: 100vw;
  }
}
</style>