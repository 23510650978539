import { createRouter, createWebHistory } from 'vue-router';

//视图

import Home from './components/Views/Home.vue';
import bv627 from './components/Views/easteregg/bv627.vue';
import events from './components/Views/events/events.vue';
import EventReplaySelector from './components/Views/events/EventReplaySelector.vue';
import Player from './components/Views/events/EventVideoPlayer.vue';

// import ls from './components/Views/services/vdslivestream.vue';
// import vdslivechannel from './components/Views/services/vdslivechannel.vue';

//错误

import Error404 from './components/Views/err/404.vue';



const routes = [
  //视图
  { path: '/', name: 'vc-home', component: Home },
  { path: '/bv627', name: 'vc-bv627', component: bv627 },
  { path: '/vds-events', name: 'vc-VDSEvents', component: events },
  { path: '/vds-events/replay', name: 'EventReplaySelector', component: EventReplaySelector },
  { path: '/vds-events/replay/play', name: 'Player', component: Player, },
  // { path: '/services/livestream', name: 'VDSLiveStream', component: ls },
  // { path: '/services/vdslivechannel', name: 'VDS-LiveChannel', component: vdslivechannel }, 
  //错误
  { path: '/404', name: 'vc-Error404', component: Error404 },

  // 捕获所有未定义的路由，跳转到 404 页面
  { path: '/:pathMatch(.*)*', name: 'Error404', component: Error404 },
];

const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 的历史模式
  routes,
});

export default router;